




























































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import api from "@/core/utils/api";
import { Presentation } from "@/core/models";
import ImageSlider from "@/components/common/ImageSlider.vue";

@Component({ components: { ImageSlider } })
export default class Slides extends Vue {
  loading = false;
  pres: Presentation | null = null;
  newFile: Blob | null = null;
  defaultName = "";

  get images() {
    if (this.pres && this.pres.Slides.length)
      return this.pres.Slides.map(x => x.Uri);
    return null;
  }

  async get() {
    this.loading = true;
    try {
      const res = (await api.get("/api/Presentations/Default")) as any;
      if (res) this.pres = Presentation.parse(res);
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  get canSubmit() {
    return this.newFile && this.defaultName;
  }
  async upload() {
    if (!this.newFile || !this.defaultName) return;
    this.loading = true;
    try {
      const data = new FormData();
      data.append("file", this.newFile);
      data.append("name", this.defaultName);
      const res = (await api.post("/api/Presentations/Default", data, {
        headers: { ContentType: "multipart/form-data" },
      })) as any;
      this.pres = Presentation.parse(res);
      this.defaultName = "";
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  editing = false;
  newName = "";
  toggleEditing() {
    if (!this.editing) this.newName = this.pres?.Name || "";
    else this.edit();
    this.editing = !this.editing;
  }
  get canSubmitEdit() {
    return this.newName.length >= 3;
  }
  async edit() {
    if (!this.pres || !this.newName) return;
    if (this.pres.Name === this.newName) return;
    this.loading = true;
    try {
      const name = this.newName;
      await api.put(`/api/Presentations/Default/${name}`);
      this.pres.Name = name;
      this.newName = "";
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  async remove() {
    if (!window.confirm(this.$t("def_pres.confirm").toString())) return;
    this.loading = true;
    try {
      await api.delete("/api/Presentations/Default");
      this.pres = null;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  created() {
    this.get();
  }
}
